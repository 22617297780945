/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'terminal-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 3a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H2a2 2 0 01-2-2zm9.5 5.5h-3a.5.5 0 000 1h3a.5.5 0 000-1m-6.354-.354a.5.5 0 10.708.708l2-2a.5.5 0 000-.708l-2-2a.5.5 0 10-.708.708L4.793 6.5z"/>',
    },
});
